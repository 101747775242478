import { postRequest, putRequest, deleteRequest, getRequest } from '../request'

export const getContactGroupList = (aid) => {
    return getRequest('/contact/group/groups?accountId=' + aid)
}

export const getContactGroupListPage = (res) => {
    return postRequest('/contact/group/groups', res)
}

export const getContactListPage = (code) => {
    return postRequest('/contact/contact/contacts', code)
}

export const saveContactGroup = (res) => {
    if (res.groupId === 9999) {
        //添加分组
        return postRequest('/contact/group/group', res);
    }
    else {
        //更新分组
        return putRequest('/contact/group/group', res);
    }
}

export const deleteContactGroup = (res) => {
    return deleteRequest('/contact/group/group/' + res.groupId + "?logic=" + res.logic)
}

export const saveContact = (res) => {
    if (res.contactId === 9999) {
        //添加联系人
        return postRequest('/contact/contact/contact', res);
    }
    else {
        //更新联系人
        return putRequest('/contact/contact/contact', res);
    }
}

export const deleteContact = (res) => {
    return deleteRequest('/contact/contact/contact/' + res.contactId + "?logic=" + res.logic)
}

export const getContactByGroupId = (groupId) => {
    return getRequest('/contact/contact/contact/' + groupId);
}