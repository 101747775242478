import { getRequest, postRequest, putRequest, deleteRequest } from '../request'

export const getTemplateInfoList = (aid) => {
    return getRequest('/template/templates?aid=' + aid)
}

export const getTemplateInfoListPage = (res) => {
    return postRequest('/template/templates', res)
}

export const saveTemplateInfo = (res) => {
    if (res.id) {
        //更新短信模板
        return putRequest('/template/template', res);
    }
    else {
        //添加短信模板
        return postRequest('/template/template', res);
    }
}

export const updateTemplateStatus = (res) => {
    return putRequest('/template/template/status', res)
}

export const deleteTemplateInfo = (res) => {
    return deleteRequest('/template/template/' + res.id + "?logic=" + res.logic)
}